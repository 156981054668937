
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Paging from "@/components/Paging.vue";
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch,
} from "vue-property-decorator";

@Component({
  name: "InfoInquiry",
  props: {},
  components: { Paging, Breadcrumbs },
})
export default class InfoInquiry extends Vue {
  photos: Array<string> = [];
  condtionVisible: boolean = false;
  zzlApi: any;
  zzlFormat: any;
  condition: any = {
    companyName: "",
  };
  desc: string = ""; // 批量查询数据
  table: any = {
    pageNum: 1,
    pageSize: 20,
    total: 0,
  };
  tableData: any = [];
  stars: any = "";
  currentVal: any = 1; // 查询条件接口值
  tips: any = {
    total: 0,
    success: 0,
  };
  importExcelFile: any = [];
  exportValue: any = "";
  exportPageNum: any = 1; //导出页码
  exportPageSize: any = 5000; //导出单页数据条数
  flag: any = true;
  cancelledNum = 0;
  cancelledOther = "";
  indoorStaff = "";
  idArr: any = [];
  //computed
  //get example() {}
  //
  // 重置
  cancel() {
    if (this.desc === "") {
      this.$message.warning("没有任何数据重置");
      return;
    }
    this.$confirm("此操作将重置当前输入数据，是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        this.desc = "";
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消操作",
        });
      });
  }
  searchAction() {
    let companyName = this.condition.companyName.trim();
    let flag = this.zzlFormat.validateIdCard(companyName);
    // if (companyName === "") {
    //   this.$message.error("请输入公司");
    //   return;
    // }
    this.searchData();
  }
  // 搜索数据
  searchData() {
    this.table.pageNum = 1;
    this.tips.total = 0;
    this.getList();
  }
  // 重置
  resetCondition() {
    this.condition.companyName = "";
    this.tableData = [];
    this.table.total = 0;
    this.tips.total = 0;
  }
  async getList() {
    let params = {
      ...this.table,
      by: 1,
      order: "entry_date",
      companyName:this.condition.companyName
    };
    const response = await this.zzlApi.home.GetCompanyList(params, true, true);
    if (response.code === 0) {
      this.tableData = response.data.list;
      this.table.total=response.data.count;
    } else {
      this.$message.error("查询错误，请联系管理员！");
    }
  }
  async editCompany(item:any) {
    let {id, name, abbreviation, companyAbbreviation, companyType, location, phone, linkMan} = item
    let params = {
      id,
      name,
      abbreviation,
      companyAbbreviation,
      companyType,
      location,
      phone,
      linkMan
    }
    const res = await this.zzlApi.home.UpdateCompany(params,true,true);
    if(res.code === 0) {
      this.$message.success('操作成功');
    }
  }
  //lifecycle
  async mounted() {
  }
}
